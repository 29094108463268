import React from 'react';
import PersonalLoanToGoPagePartnersContent from '@/components/PersonalLoanPageContent/PersonalLoanToGoPagePartnersContent';

const PartenerLoanPage = props => {
  return (
    <PersonalLoanToGoPagePartnersContent
      {...props}
      title="Personal Loan 2 Go - Fixed Interest Rate"
      description="Whether your dream is to renovate your home or go travelling, Latitude's personal loan can help make that come true faster. Apply in less than 10 minutes."
    />
  );
};

export default PartenerLoanPage;
